import './App.css';
import 'antd/dist/antd.css'
import LayoutPage from './components/LayoutPage';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { getExternalFile } from "./lib/func";

function Auth() {
  return <iframe
    id="inlineFrameExample"
    title="Inline Frame Example"
    width="100%"
    height="100%"
    src="https://www.followmytrack.ru/auth.htm">
  </iframe>
}

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LayoutPage page={"main"} />} />
        <Route path="/auth" element={<Auth />} />
        <Route path="/map_v1" element={<LayoutPage page={"devices"} />} />
        <Route path="/map_v2" element={<LayoutPage page={"cavemap"} />} />
        <Route path="/forum" element={<LayoutPage page={"info"} />} />
        <Route path="/caves/:id" element={<LayoutPage page={"cavecard"} />} />
        <Route path="/caves" element={<LayoutPage page={"cavelist"} />} />
        <Route path="/gallery" element={<LayoutPage page={"gallery"} />} />
        <Route path="/posts/:id" element={<LayoutPage page={"postcard"} />} />
        <Route path="*" element={<LayoutPage page={"404"} />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
