// авторизация ВК
import * as VKID from "@vkid/sdk";

import {
  PageHeader,
  Card,
  List,
  Avatar,
  Skeleton,
  Modal,
  Button,
  Form,
  Input,
  Divider,
  Space,
  Image,
  message,
  Upload,
} from "antd";
import Picker from "emoji-picker-react";

import { Tag, Carousel } from "antd";
import {
  SmileOutlined,
  SendOutlined,
  RotateRightOutlined,
  SwapOutlined,
  RotateLeftOutlined,
  ZoomOutOutlined,
  ZoomInOutlined,
  UploadOutlined,
  UserOutlined,
  CommentOutlined,
} from "@ant-design/icons";
import { useEffect, useState } from "react";
import TextArea from "antd/lib/input/TextArea";
import { generateNewId } from "../lib/apiReq";
import { jsParseDate } from "../lib/func";

const InfoPage = () => {
  const vk = () => {
    VKID.Config.init({
      app: 52865985,
      redirectUrl: "https://www.followmytrack.ru",
      responseMode: VKID.ConfigResponseMode.Callback,
      source: VKID.ConfigSource.LOWCODE,
      scope: "", // Заполните нужными доступами по необходимости
    });

    const oneTap = new VKID.OneTap();

    oneTap
      .render({
        container: document.querySelector("div"),
        showAlternativeLogin: true,
      })
      .on(VKID.WidgetEvents.ERROR, vkidOnError)
      .on(VKID.OneTapInternalEvents.LOGIN_SUCCESS, function (payload) {
        const code = payload.code;
        const deviceId = payload.device_id;

        VKID.Auth.exchangeCode(code, deviceId)
          .then(vkidOnSuccess)
          .catch(vkidOnError);
      });

    function vkidOnSuccess(data) {
      console.log(data);
    }

    function vkidOnError(error) {
      console.log(error);
    }
  };
  return (
    <div className="alphaback">
      <PageHeader
        className="site-page-header"
        style={{ width: "100%", paddingBottom: "10px" }}
        title="Авторизация"
      />

      <div> {vk()}</div>

      <p style={{ padding: "0 15px" }}>
        Данный ресурс является проектом cообщества FollowMyTrack
      </p>
      <p style={{ padding: "0 15px" }}>
        Мы - исследователи пещер, путешественники и увлеченные люди. Мы собрали
        в едином каталоге данные по подземным полостям горного Крыма. Тут можно
        найти координаты пещер и посмотреть их на карте. По нашему кадастру
        можно производить поиск и фильтрацию пещер по заданным критериям.
      </p>
      <div style={{ padding: "0 15px" }}>
        Мы пополняем данные находя новые пещеры и новые ходы существующих пещер
        Приглашаем к участию в наших проектах. Связаться с нами можно в форме
        обратной связи или в сообществе{" "}
        <a href="http://vk.com/followmytrack">FollowMyTrack </a> в VK.
        <br />
      </div>
      <br />
    </div>
  );
};

export default InfoPage;
